// export const API_URL = 'https://peleka-market-dev-api.herokuapp.com';
export const API_URL = process.env.REACT_APP_API_URL
export const API_KEY = process.env.REACT_APP_API_KEY
export const APP_URL = process.env.REACT_APP_PUBLIC_URL
export const token_string = localStorage.getItem('token');
export const buyer_city = localStorage.getItem('buyer_city');

export const get_date = (firestore_timestamp) => new Date(firestore_timestamp.seconds * 1000);

export const get_month = (month_number) => {
    switch (month_number) {
    case 1:
      return 'January';
    case 2:
      return 'February';
    case 3:
      return 'March';
    case 4:
      return 'April';
    case 5:
      return 'May';
    case 6:
      return 'June';
    case 7:
      return 'July';
    case 8:
      return 'August';
    case 9:
      return 'September';
    case 10:
      return 'October';
    case 11:
      return 'November';
    case 12:
      return 'December';
    default:
      break;
    }
  };

export const get_date_string = (firestore_timestamp) => {
    // console.log('in');
    let created_date;
    created_date = firestore_timestamp;
    // console.log('in', created_date);
  
    if (firestore_timestamp.seconds) {
      created_date = get_date(firestore_timestamp);
    }
  
    const day = created_date.getDate() < 10 ? `0${created_date.getDate()}` : created_date.getDate();
    // const seconds = created_date.getSeconds() < 10 ? `0${created_date.getSeconds()}` : created_date.getSeconds();
    const minutes = created_date.getMinutes() < 10 ? `0${created_date.getMinutes()}` : created_date.getMinutes();
    const hours = created_date.getHours() < 10 ? `0${created_date.getHours()}` : created_date.getHours();
  
    const created_date_string = `${day} ${get_month(created_date.getMonth() + 1)} ${created_date.getFullYear()} at ${hours}:${minutes}`;
  
    return created_date_string;
  };

  export const get_display_date = (mongo_date_string) => {
    
    // let firestore_timestamp = ;
    let firestore_timestamp = new Date(new Date(mongo_date_string));

    

    const created_date = firestore_timestamp;

    const now = new Date();
  
    const created_date_utc = Date.UTC(
      created_date.getFullYear(),
      created_date.getMonth(),
      created_date.getDate(),
      created_date.getHours(),
      created_date.getMinutes(),
      created_date.getSeconds(),
      created_date.getMinutes(),
    );
    const now_utc = Date.UTC(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      now.getHours(),
      now.getMinutes(),
      now.getSeconds(),
      now.getMilliseconds(),
    );
  
    const seconds_ago = Math.floor((now_utc - created_date_utc) / (1000));
  
    if (seconds_ago < 60) {
      return `${seconds_ago} ${seconds_ago === 1 ? 'second' : 'seconds'} ago`;
    }
  
    const minutes_ago = Math.floor((now_utc - created_date_utc) / (1000 * 60));
  
    if (minutes_ago < 60) {
      return `${minutes_ago} ${minutes_ago === 1 ? 'min' : 'mins'} ago`;
    }
  
    const hours_ago = Math.floor((now_utc - created_date_utc) / (1000 * 60 * 60));
  
    if (hours_ago < 24) {
      return `${hours_ago} ${hours_ago === 1 ? 'hour' : 'hours'} ago`;
    }
  
    // return 'nothing';
    return get_date_string(firestore_timestamp);
  };

  export const PELEKA_DELIVERY_CITIES = [
    'Gweru', 'Gwelo', 'gweru', 'gwelo', 'GWERU', 'GWELO'
  ];

  export const COUNTRIES = [ "Afghanistan",
  "Aland Islands",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bonaire, Sint Eustatius and Saba",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo, Democratic Republic of the Congo",
  "Cook Islands",
  "Costa Rica",
  "Cote D'Ivoire",
  "Croatia",
  "Cuba",
  "Curacao",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands (Malvinas)",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and Mcdonald Islands",
  "Holy See (Vatican City State)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran, Islamic Republic of",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, Democratic People's Republic of",
  "Korea, Republic of",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libyan Arab Jamahiriya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Macedonia, the Former Yugoslav Republic of",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia, Federated States of",
  "Moldova, Republic of",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestinian Territory, Occupied",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reunion",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Saint Barthelemy",
  "Saint Helena",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Serbia and Montenegro",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan, Province of China",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "United States Minor Outlying Islands",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Viet Nam",
  "Virgin Islands, British",
  "Virgin Islands, U.s.",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe", ]

